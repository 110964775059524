import React, {useEffect, useState} from "react";
import {Field} from "formik";
import {Col, Row, Form, Button} from "react-bootstrap";
import {ChangedFlag} from "models/changedFlag";
import SupportingDataService from "services/survey_definition/supportingData";
import {retrieveToken} from "utils";

const ResponseSetChoices = (props) =>{

  let token = retrieveToken();
  let supportingDataService = new SupportingDataService(token);
  let [supportingDatums, setSupportingDatums] = useState([]);
  
  useEffect(()=>{
    supportingDataService.retrieve()
      .then(datums => {
        setSupportingDatums(datums);
      });
  }, []);

  const HandleUpdate = (choice, index) => {
    choice.changedFlag = ChangedFlag.Update;
    props.replace(index, choice);
  };

  const handleChange = (event) => {
    let  index = event.nativeEvent.target.selectedIndex;
    props.form.setFieldValue("responseSetTemplateId", event.nativeEvent.target[index].value);
    props.form.setFieldValue("responseSetTemplateDescription", event.nativeEvent.target[index].text);
  };

  let display = parseInt(props.form.values.responseSet) === 2 || parseInt(props.form.values.responseSet) === 3;

  const style = {
    display: display ? 'block' : 'none'
  };

  return (
    <>
    {parseInt(props.form.values.responseSet) === 6 
    ? 
    <div>
    <Form.Group>
      <Form.Label>Supporting Data: </Form.Label>
      <select
        name={"responseSetDescription"}
        value={props.form.values.responseSetTemplateDescription}
        className={`form-control ${props.form.errors.choices ? "is-invalid" : ""}`}
        onChange={handleChange}>
        <option value={0}>Select</option>
        {
          supportingDatums && supportingDatums.map((template, index) => {
          return <option key={index} value={template.description}>{template.description}</option>
          })
        }
      </select>
      <Form.Control.Feedback type="invalid">
        {props.form.errors.choices}
      </Form.Control.Feedback>
    </Form.Group>
    </div>
    : '' }
    <div style={style}>
      {props.form.values.choices && props.form.values.choices.map((choice, index) => {
        return <Row key={index}>
         <Col lg={6}>
              <Form.Group>
                <Form.Label>Text: </Form.Label>
                <Field
                  required
                  className="form-control"
                  name={`choices.${index}.text`}
                  value={choice.text}
                  onClick={() => {
                    HandleUpdate(choice, index);
                  }}
                />
              </Form.Group>
         </Col>

          <Col lg={2}>
            <Form.Group>
              <Form.Label>Value: </Form.Label>
              <Field
                required
                className="form-control"
                name={`choices.${index}.value`}
                value={choice.value}
                onClick={() => {
                  HandleUpdate(choice, index);
                }}
              />
            </Form.Group>
          </Col>

          <Col lg={2}>
            <div className="form-group">
              <Form.Label>Sort: </Form.Label>
              <Field
                required
                className="form-control"
                type="number"
                name={`choices.${index}.position`}
                value={choice.position}
                onClick={() => {
                  HandleUpdate(choice, index);
                }}
              />
            </div>
          </Col>
          <Col lg={1}>
            <div className="form-group">
            <Form.Label>&nbsp;</Form.Label>
            <Button
              variant="outline-danger"
              onClick={() => {
                props.form.values.responseSetTemplateDescription = "Custom"
                props.form.values.responseSetTemplateId = 0
                props.remove(index);
              }}
            >
              Delete
            </Button>
            </div>
          </Col>
        </Row>
      })}
      <Button
        variant="outline-success"
        onClick={() => {
          props.form.values.responseSetTemplateDescription = "Custom"
          props.form.values.responseSetTemplateId = 0
          props.push({
            id: 0,
            text: "",
            value: "",
            position: "",
          })
        }}
      >
        New
      </Button>
    </div>
  </>
  )
};

export default ResponseSetChoices;
