import React, {useState} from 'react';
import {Container, Badge, Dropdown} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {textFilter, selectFilter} from 'react-bootstrap-table2-filter';
import paginationFactory from "react-bootstrap-table2-paginator";
import {Person, PeopleFill, ExclamationTriangle, Gear, ThreeDots} from 'react-bootstrap-icons';
import SurveyErrors, {SurveyErrorsEntity} from './surveyErrors';
import {DownloadResponseRequestDocument} from 'models/surveyResponse/downloadResponseRequestEntity';
import SurveyResponseManager from "managers/survey_response/surveyResponseManager";

let DownloadResponseRequests = (props: DownloadResponseRequestProps) => {
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [rowIndex, setRowIndex] = useState<number>(-1);
  const tableData = props.downloadRequests.map(value => {
    let requestedDateString = value.requestedTimestamp.endsWith("Z") ? value.requestedTimestamp : value.requestedTimestamp + "Z";
    let ingressErrors = value.ingressStates.filter(s => s.errorMessage).length;
    let surveysErrorStatus : number;
    if(value.surveysReturned === 0)
      surveysErrorStatus = 0;
    else if (value.surveysReturned > 0 && ingressErrors === 0)
      surveysErrorStatus = 1;
    else
      surveysErrorStatus = 2;

    let status = 3;
    if(value.status === 'Completed')
      status = 0;
    else if(value.status === 'Received')
      status = 1;
    else if (value.status === 'Resubmitted')
      status = 2;

    return {
      id: value.downloadResponseRequestDocumentId,
      downloadResponseRequestId: value.downloadResponseRequestId,
      requestedTimestamp: new Date(Date.parse(requestedDateString)),
      requestType: value.requestType,
      status: value.status,
      statusValue: status,
      surveyTitle: value.surveyTitle,
      surveyId: value.surveyId,
      parameters: value.parameters,
      surveysReturned: value.surveysReturned,
      surveysErrored: ingressErrors,
      surveysErroredStatus: surveysErrorStatus,
      clientSurveyId: value.clientSurveyId
    } as TableData
  });
  let surveyResponseManager = new SurveyResponseManager(props.smToken);

  // Array options
  const selectRequestsArr = [
    {value: DownloadRequestType.Single, label: 'Single'},
    {value: DownloadRequestType.Mass, label: 'Mass Download'},
    {value: DownloadRequestType.Automated, label: 'Automated'}
  ];

  const surveysStatusFilter = selectFilter({
    placeholder: 'Select',
    options: [
      {value: 0, label: 'Completed'},
      {value: 1, label: 'Received'},
      {value: 2, label: 'Resubmitted'},
      {value: 3, label: 'Error'}]
  });

  const surveysErroredFilter = selectFilter({
    placeholder: 'Select',
    options: [
      { value: 0, label: 'No Surveys Returned' },
      { value: 1, label: 'No Errors' },
      { value: 2, label: 'Errors' },
    ]
  });

  const requestTypeFormat = (cell, row: TableData) => {
    switch (row.requestType) {
      case DownloadRequestType.Single:
        return (<Person className="iconStyle" size={24}/>);
      case DownloadRequestType.Mass:
        return (<PeopleFill className="iconStyle" size={24}/>);
      case DownloadRequestType.Automated:
      case DownloadRequestType.Webhook:
        return (<Gear className="iconStyle" size={24}/>);
    }
  };
  const resubmitRow = (row: TableData) => {
    surveyResponseManager.resubmitDownloadRequest(row)
      .then((response) => {
        if (response) {
          props.handleToast(true);
        } else {
          alert("Request failed");
        }
      });
  };

  const viewErrorFormat = (cell, row: TableData, rowIndex) => {
    if (row.surveysReturned === null) return "";
    if (row.surveysReturned === 0) return (<ExclamationTriangle className={"warning"} size={28}/>);
    if (row.surveysErrored == 0) return (<Badge variant={"success"} pill={true}>0</Badge>);

    return (
      <Badge
        variant={"danger"}
        onClick={() => {
          setRowIndex(row.downloadResponseRequestId);
          setShowErrors(true);
        }}
        pill={true}>{row.surveysErrored}
      </Badge>
    );
  }
  const parameterFormat = (cell: MassResponseDownloadParameters|SingleResponseDownloadParameters, row : TableData) => {
    switch (row.requestType) {
      case DownloadRequestType.Single:
      case DownloadRequestType.Webhook:
        let sP = cell as SingleResponseDownloadParameters;
        return `Response ID: ${sP.responderId}`;
      case DownloadRequestType.Automated:
      case DownloadRequestType.Mass:
        let m = cell as MassResponseDownloadParameters;
        let formatter = new Intl.DateTimeFormat("en-US", {timeZone: 'UTC'});
        let s = formatter.format(Date.parse(m.startDate));
        let e = formatter.format(Date.parse(m.endDate));
        return `${s} - ${e}`;
      default:
        return JSON.stringify(cell, undefined, 2);
    }
  }
  const requestTimeStampFormat = (cell: Date) => {
    let formatter = new Intl.DateTimeFormat("en-US", {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric',
      hour12: true
    });
    let temp = formatter.format(cell);
    return (<span style={{fontSize: "smaller"}}>{temp}</span>);
  }
  const getErroredIngressStates = (downloadResponseRequest: DownloadResponseRequestDocument): SurveyErrorsEntity[] => {
    if (downloadResponseRequest) {
      return downloadResponseRequest.ingressStates
        .filter(value => value.errorMessage !== undefined)
        .filter(value => value.errorMessage !== null)
        .map(value => {
          return {
            id: value.ingressStateId,
            recordedDate: new Date(Date.parse(downloadResponseRequest.requestedTimestamp)),
            surveyId: downloadResponseRequest.surveyId,
            providerId: value.providerId,
            surveyTitle: downloadResponseRequest.surveyTitle,
            errorMessage: value.errorMessage
          };
        })
    } else {
      return []
    }
  }
  const dropDownMenuFormat = (cell, row: TableData) => {
    return (<Dropdown>
      <Dropdown.Toggle variant={"outline-info"} id="dropdown-basic">
        <ThreeDots/>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={(e) => resubmitRow(row)} >Resubmit</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>);
  }
  const columns = [
    {
      dataField: "requestType",
      text: "",
      headerAlign: 'center',
      sort: true,
      filter: selectFilter({
        options: selectRequestsArr
      }),
      formatter: requestTypeFormat,
      headerStyle: () => {
        return {width: '5%'};
      },
    },
    {
      dataField: "surveyTitle",
      text: "Survey Title",
      sort: true,
      filter: textFilter(),
      headerStyle: () => {
        return {width: '20%'};
      },
    },
    {
      dataField: "parameters",
      text: "Parameters",
      formatter: parameterFormat,
      headerStyle: () => {
        return {width: '15%'};
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      filter: surveysStatusFilter,
      filterValue: (cell, row) => row.statusValue,
      headerStyle: () => {
        return {width: '5%'};
      },
    },
    {
      dataField: "surveysReturned",
      text: "Surveys Returned",
      sort: true,
      headerStyle: () => {
        return {width: '5%'};
      },
    },
    {
      dataField: "_dataFieldViewError",
      isDummyField: true,
      text: "Surveys Errored",
      sort: true,
      filter: surveysErroredFilter,
      filterValue: (cell, row) => row.surveysErroredStatus,
      formatter: viewErrorFormat,
      headerStyle:() => {return {width: '5%'}}
    },
    {
      dataField: "requestedTimestamp",
      text: 'Date',
      type: "date",
      sort: true,
      formatter: requestTimeStampFormat,
      headerStyle: () => {
        return {width: '10%'};
      },
    },
    {
      dataField: "_dataFieldViewDropDownMenu",
      isDummyField: true,
      text:"",
      formatter: dropDownMenuFormat,
      headerStyle: () => {
        return {width: '1%'};
      },
    }
  ];

  return (
    <Container fluid={true}>

      <BootstrapTable
        keyField="downloadResponseRequestId"
        data={tableData}
        columns={columns}
        condensed
        filter={filterFactory()}
        pagination={paginationFactory()}
      />


      <SurveyErrors
        smToken={props.smToken}
        erroredSurveys={getErroredIngressStates(props.downloadRequests.filter(x => x.downloadResponseRequestId === rowIndex)[0])}
        show={showErrors}
        onHide={() => {
          setShowErrors(false);
        }}
      />

    </Container>
  );
};

interface DownloadResponseRequestProps {
  downloadRequests: DownloadResponseRequestDocument[];
  smToken: string;
  handleToast(isSuccess: boolean);
}
interface TableData {
  id : number;
  downloadResponseRequestId : number;
  requestType: DownloadRequestType;
  surveyTitle: string;
  surveyId: string;
  clientSurveyId: number;
  parameters: MassResponseDownloadParameters | SingleResponseDownloadParameters;
  requestedTimestamp: Date;
  status: string;
  statusValue: number;
  surveysReturned?: number;
  surveysErrored?: number;
  surveysErroredStatus: number;
}
interface MassResponseDownloadParameters {
  startDate: string;
  endDate: string;
}
interface SingleResponseDownloadParameters {
  responderId: string;
}
enum DownloadRequestType {
  Mass= "Mass Download",
  Single = "Single",
  Automated = "Automated",
  Webhook = "Webhook"
}
export default DownloadResponseRequests;
