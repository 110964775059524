import {Question} from "managers/survey_definition/models";
import {ItemTypesBySurveyTypesForm} from "./itemTypesBySurveyTypesForm";
import {FieldArray, FieldProps} from "formik";
import React, {FC} from "react";
import "components/survey_definition/question/question_form/item_type/itemType.css";

const ItemTypeForm : FC<ItemTypeFormParameters>= (props: ItemTypeFormParameters) => {
  return (
    <>
      <legend className="w-auto">Question Types</legend>
      <FieldArray name="itemTypesBySurveyTypes">{(fieldArrayProps) =>
        <ItemTypesBySurveyTypesForm {...fieldArrayProps} itemTypes={props.itemTypes}/>
      }</FieldArray>
    </>
  );
};

interface ItemTypeFormParameters extends FieldProps<Question> {
  itemTypes: ItemType[];
}
interface ItemType {
  id:number;
  description:string;
}
export {ItemTypeForm};
