import React from "react";
import {Field, FieldArray} from "formik";
import StandardMatrixType from "./matrix_forms/standard";
import SupportingDataMatrixType from "./matrix_forms/supportingData";
import {Form} from "react-bootstrap";

const MatrixType = (props) =>{

  let matrixTypes = [
    {id:1, description:"No Matrix"},
    {id:2, description:"Standard Matrix"},
    {id:3, description:"Supporting Data Matrix"}
  ];
  return (
    <>
      <legend className="w-auto">Question Matrix Types</legend>
      <Form.Group>
      <label>Select Type:</label>
      <Field
        as="select"
        value={props.form.values.matrixType}
        name="matrixType"
        className={`form-control ${props.form.touched.matrixType && (props.form.errors.matrixType || props.form.errors.matrixItems) ? "is-invalid" : ""}`}
      >
        <option value={0}>Select</option>
        {
          matrixTypes.map((option, index) => {
            return <option key={index} value={option.id}>{option.description}</option>
          })
        }
      </Field>
        <Form.Control.Feedback type="invalid">
          {props.form.errors.matrixType}
        </Form.Control.Feedback>
        <Form.Control.Feedback type="invalid">
          {props.form.errors.matrixItems}
        </Form.Control.Feedback>
      </Form.Group>

      <FieldArray
        component={StandardMatrixType}
        matrixItems={props.matrixItems}
        display={parseInt(props.matrixType) === 2}
        className={`form-control ${props.form.errors.matrixItems ? "is-invalid" : ""}`}
      />
      <SupportingDataMatrixType
        {...props}
        supportingDataId={props.supportingDataId}
        display={parseInt(props.matrixType) === 3}
      />
    </>
  );
};

export default MatrixType;
