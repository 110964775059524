import React, {useState} from "react";
import {FieldArray,Field} from "formik";
import Button from 'react-bootstrap/Button'
import {Col, Form, Row} from "react-bootstrap";
import {ChangedFlag} from "models/changedFlag";

const QuestionWordings = (props) =>{
  let [newWording, setNewWording] = useState("");

  const HandleChange = (event) => {
  const clickedIndex = event.target.getAttribute('dataindex');

    props.form.values.wordings.forEach((x, index) => {

      if(x.changedFlag !== 1) {
        props.form.setFieldValue(`wordings.${index}.default`, false);
        props.form.setFieldValue(`wordings.${index}.changedFlag`, 3);
      }

      if(parseInt(index) === parseInt(clickedIndex)) {
        props.form.setFieldValue(`wordings.${index}.default`, true);
      } else {
        props.form.setFieldValue(`wordings.${index}.default`, false);
      }

    });
  };

  return (
    <fieldset className="border p-2">
      <legend className="w-auto">Question Wordings</legend>

      <FieldArray name="wordings">
        {(arrayHelpers)=> (

          <>
            <Form.Group controlId="alternativeWording">
              <Row>
                <Col lg={9}>
                  <Field as={Form.Control}
                    name="tempWording"
                    value={newWording}
                    onChange={e => setNewWording(e.target.value)}
                    className={`form-control ${(props.form.touched.tempWording || props.form.touched.wordings ) && props.form.errors.wordings ? "is-invalid" : ""}`}
                    placeholder="Add additional wording"
                  />

                  <Form.Control.Feedback type="invalid">
                    {props.form.errors.wordings}
                  </Form.Control.Feedback>
                </Col>
                <Col lg={3}>
                  <Button variant="outline-success" className={"btn-block"} onClick={() => {
                    if(newWording !== '') {
                      arrayHelpers.push({
                        id: 0,
                        wording: newWording,
                        changedFlag: ChangedFlag.New,
                        default: props.form.values.wordings.length === 0
                      });
                    }
                    setNewWording("");
                    props.form.setErrors("");
                  }}>
                    Add Wording
                  </Button>
                </Col>
              </Row>
            <Row>
              <Col lg={2}>
                Default
              </Col>
              <Col lg={8}>Wording</Col>
              <Col lg={1}>&nbsp;</Col>
            </Row>
              {props.form.values.wordings.map((itemType, index) => {
                return(
                <Row key={index} lg={12} value={itemType.id}>
                  <Col lg={2}>
                    <div className="form-group">
                    <Field as={Form.Check}
                       type="checkbox"
                       checked={itemType.default}
                       name={`wordings.${index}.default`}
                       dataindex={index}
                       onChange={HandleChange}
                     />
                     </div>
                  </Col>
                  <Col lg={8}>
                    <div className="form-group">
                      {itemType.wording}
                    </div>
                </Col>
                   <Col lg={1}>
                     <div className="form-group">
                      <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={(event) => {
                          if (props.form.values.wordings.length > 1)
                          {
                            props.form.values.deleted_wordings.push({
                              "id":props.form.values.wordings[index].id,
                              "wording":props.form.values.wordings[index].wording,
                              "default": props.form.values.wordings[index].default,
                              "changedFlag": ChangedFlag.Deleted
                            });

                            arrayHelpers.remove(index);

                            if (props.form.values.wordings[index].default === true) {
                              props.form.setFieldValue('wordings.0.default', true);
                              props.form.setFieldValue('wordings.0.changedFlag', ChangedFlag.Update);
                            }
                          }
                        }}
                      >
                     Delete
                    </Button>
                     </div>
                    </Col>
              </Row>)
              })}

            </Form.Group>
          </>
        )}
      </FieldArray>
    </fieldset>
  );
};

export default QuestionWordings;
