import {ItemTypeBySurveyType} from "managers/survey_definition/models";
import ItemType from "models/itemType";
import {Col, Row} from "react-bootstrap";
import {Field} from "formik";
import React from "react";

export const ItemTypesBySurveyTypesRow = (props: ItemTypesBySurveyTypesRowParameters) => {
  const {itemTypeBySurveyType, index} = props;
  return (
    <Row className="rowColor">
      <Col xs={4} lg={4}>{itemTypeBySurveyType.name}</Col>
      <Col xs={4} lg={4}>{itemTypeBySurveyType.group}</Col>
      <Col xs={4} lg={4}>
        <Field
          as={"select"}
          name={`itemTypesBySurveyTypes.${index}.itemTypeId`}
          value={itemTypeBySurveyType.itemTypeId ? itemTypeBySurveyType.itemTypeId : 0}
          className={"form-control"}
        >
          <option value={0}>&nbsp;</option>

          {props.itemTypes.map(itemType => {
            return <option key={itemType.id} value={itemType.id}>
              {itemType.description}
            </option>
          })}
        </Field>
      </Col>
    </Row>
  );
};

interface ItemTypesBySurveyTypesRowParameters {
  itemTypeBySurveyType: ItemTypeBySurveyType
  itemTypes: ItemType[],
  index: number
}
