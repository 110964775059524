import Client from "models/client";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

export default class ClientService {
  private client: AxiosInstance;

  constructor(token: string) {
    let config: AxiosRequestConfig = {
      baseURL: process.env.REACT_APP_API_BASEURL,
      headers: {
        "content-type": "application/json",
        "Authorization": "bearer " + token
      }
    };

    this.client = axios.create(config);
  }

  getList(): Promise<Client[]> {
    return this.client.get<Client[]>('api/v2/client')
      .then(x => x.data)
      .catch(e => {
        return [];
      })
  }
  getNewList(): Promise<Client[]> {
    return this.client.get<Client[]>('/api/v2/client/new')
      .then(x => x.data)
      .catch(e => {
        return [];
      })
  }

  getUsersList(): Promise<any[]> {
    return this.client.get<any[]>('api/v2/user')
      .then(x => x.data)
      .catch(e => {
        return [];
      })
  }
  getThresholdsList(): Promise<any[]> {
    return this.client.get<any[]>('api/v2/intervention-type')
      .then(x => x.data)
      .catch(e => {
        return [];
      })
  }

  getStatusList(): Promise<any[]> {
    return this.client.get<any[]>('/api/v2/clientstatus')
      .then(x => x.data)
      .catch(e => {
        return [];
      })
  }

  getClientById(id: number) {
    return this.client.get<Client[]>("api/v2/client/" + id)
      .then(function (definitions: { data: any; }) {
        return definitions.data;
      })
      .catch((er: any) => console.log(er));
  }

  insertClient(client: Client): Promise<any> {
    return this.client
      .post("/api/v2/client", client)
      .then((x: any) => x)
      .catch((er: any) => console.log(er));
  }

  updateClient(id: string, client: Client,): Promise<any> {
    return this.client
      .put(`api/v2/client/${id}`, client)
      .then((x: AxiosResponse<any>) => x)
      .catch((er: any) => console.log(er));
  }

}
