import React, {useState} from 'react';
import {Container, Tab, Tabs} from "react-bootstrap";
import QuestionForm from "../question_form";
import QuestionReportingData from "../question_reporting_data";

const QuestionFormTabs = (props) => {

  const [currentTab, setCurrentTab] = useState('main');

  let questionId = props.match.params.questionId;

  return(
    <Container fluid={true}>
      <Tabs id="question-tabs" activeKey={currentTab} onSelect={k => setCurrentTab(k)}>
        <Tab eventKey="main" title="Main Data">
          <QuestionForm
            questionId={props.match.params.questionId}
            currentuser={props.currentuser}
          />
        </Tab>
        {questionId !== undefined
          ?
          <Tab eventKey="other" title="Reporting Data">
            <QuestionReportingData
              questionId={props.match.params.questionId}
            />
          </Tab>
          :
          ''
        }

      </Tabs>
    </Container>
  );

};

export default QuestionFormTabs;
