import ClientService from "services/clientService";
import Client from "models/client";

export default class ClientManager {
  private clientService: ClientService;

  constructor(token: string) {
    this.clientService = new ClientService(token);
  }

  //depreciated.... use getNewList
  getList(): Promise<Client[]> {
    return this.clientService.getList()
      .then(x => {
        x.sort((a, b) => (a.name > b.name) ? 1 : -1);
        return x
      });
  }

  getParentClientList(): Promise<Client[]> {
    return this.clientService.getNewList()
      .then(x => {
        x.sort((a, b) => (a.name > b.name) ? 1 : -1);
        return x
      });
  }


  getNewList(): Promise<Client[]> {
    return this.clientService.getNewList()
      .then(x => {
        x.sort((a, b) => (a.name > b.name) ? 1 : -1);
        return x
      });
  }

  getUsersList(): Promise<any> {
    return this.clientService.getUsersList().then(x => {
        x.sort((a, b) => (a.lastNname > b.lastName) ? 1 : -1);
        return x.map(item => ({ label: item.firstName + " " + item.lastName, value: item.id }));
    });
  }

  getThresholdsList(): Promise<any> {
    return this.clientService.getThresholdsList().then(x => {
        return x;
    });
  }

  getStatusList(): Promise<any> {
    return this.clientService.getStatusList().then(x => {
        return x.map(item => ({ label: item.name, value: item.id}));;
    });
  }

  getClientById(id: number): Promise<Client> {
    return this.clientService.getClientById(id).then((x: Client) => x)
  }

  insert(client: Client): Promise<boolean> {
    return this.clientService.insertClient(client)
      .then(result => result);
  }

  update(id: string, client: Client): Promise<boolean> {
    return this.clientService.updateClient(id, client).then((result: boolean) => result)
  }

  static blankObject(): Client {
    return {
      id: "",
      name: "",
      powerBiWorkspaceId: "",
      stayMetricsId: 0,
      status: "",
      interventionThreshold: [
        {
          categoryName: "",
          clientSalesforceId: "",
          defaultIsEnabled: false,
          defaultThresholdValue: 0,
          interventionTypeId: 0,
          isEnabled: 0,
          longDesc: "",
          shortDesc: "",
          sortWithinCategory: 0,
          thresholdValue: 0,
        }
      ]
    };
  }

}
