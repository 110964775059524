import React from "react";
import {Field} from "formik";
import MultipleChoiceResponseSetType from "./response_set_forms/multiple_choice";
import Form from "react-bootstrap/Form";

const QuestionResponseSet = (props) =>{

  const UpdateSet = ()=> {
    if (parseInt(props.responseSet) !== 2 || parseInt(props.responseSet) !== 3)
    {
      props.form.values.choices = [];
    }
  }

  return (
    <>
      <label>Select Type:</label>
      <Field
        as="select"
        value={props.responseSet}
        name="responseSet"
        className={`form-control ${props.form.touched.responseSet && props.form.errors.responseSet ? "is-invalid" : ""}`}
        onClick={UpdateSet}
      >
        <option value={0}>Select</option>
        {props.types.map((option, index) => {
          return <option key={index} value={option.id}>{option.description}</option>
        })}
      </Field>

      <Field
        component={MultipleChoiceResponseSetType}
        types={props.types}
        display={ parseInt(props.responseSet) === 2 || parseInt(props.responseSet) === 3}
      />
      <Form.Control.Feedback type="invalid">
        {props.form.errors.responseSet}
      </Form.Control.Feedback>
    </>
  );
};

export default QuestionResponseSet;
