import React, {FC} from "react";
import {ItemTypeBySurveyType, Question} from "managers/survey_definition/models";
import ItemType from "models/itemType";
import {ItemTypesBySurveyTypesRow} from "./itemTypesBySurveyTypesRow";
import {FieldArrayRenderProps, FormikProps} from "formik";

export const ItemTypesBySurveyTypesForm : FC<ItemTypesBySurveyTypesFormParameters> = (props: ItemTypesBySurveyTypesFormParameters) => {
  const itemsSort = (a: ItemTypeBySurveyType, b: ItemTypeBySurveyType): number => {
    return a.group.localeCompare(b.group) || a.name.localeCompare(b.name);
  };
  return (
    <>
      {props.form.values.itemTypesBySurveyTypes.sort(itemsSort).map((value, index) => {
        return <ItemTypesBySurveyTypesRow itemTypeBySurveyType={value}
                                          key={index}
                                          itemTypes={props.itemTypes}
                                          index={index}/>;
      })}
    </>
  );
};

interface ItemTypesBySurveyTypesFormParameters extends FieldArrayRenderProps {
  form: FormikProps<Question>;
  itemTypes: ItemType[];
}
