import React from "react";
import {Form} from "react-bootstrap";
import {ChangedFlag} from "models/changedFlag";

const MultipleChoiceResponseSetType = (props) => {

  const style = {
    display: props.display ? 'block' : 'none'
  };

  let templates = props.types.filter(x => x.id === parseInt(props.form.values.responseSet));

  if(templates.length > 0) {
    templates = templates[0].templates;
  } else {
    templates = [];
  }

  const handleChange = (event) => {
    // Set the choices in the form to be the choices from the template
    let newChoices = templates.filter(x => x.id === parseInt(event.target.value));

    if (typeof(newChoices[0]) !== 'undefined') {
      newChoices = newChoices[0].choices;
    }

    let newChoiceObj = [];
    newChoices.forEach(x => newChoiceObj.push({
      "id": 0,
      "text": x.text,
      "value": x.value,
      "position": x.position,
      "changedFlag": ChangedFlag.New
    }));

    let  index = event.nativeEvent.target.selectedIndex;
    props.form.setFieldValue("responseSetTemplateId", event.nativeEvent.target[index].value);
    props.form.setFieldValue("responseSetTemplateDescription", event.nativeEvent.target[index].text);
    props.form.setFieldValue('mass_choice_change', true);
    props.form.setFieldValue('choices', newChoiceObj);
  };

  return (
    <Form.Group style={style}>
      <Form.Label>Template: </Form.Label>
      <select
        name={"responseSetDescription"}
        className={`form-control ${props.form.errors.choices ? "is-invalid" : ""}`}
        onChange={handleChange}>
        <option value={0}>Select</option>
        {
          templates && templates.map((template, index) => {
          return <option key={index} value={template.id}>{template.description}</option>
          })
        }
      </select>
      <Form.Control.Feedback type="invalid">
        {props.form.errors.choices}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default MultipleChoiceResponseSetType;
