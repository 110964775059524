import React from "react";
import { Field } from "formik";

const QuestionNotes = (props) =>{
  return (
    <fieldset className="border p-2">
      <legend className="w-auto">Notes</legend>
      <Field as="textarea" className="form-control" name="notes" value={props.notes} rows="10"/>
    </fieldset>
  );
};

export default QuestionNotes;
