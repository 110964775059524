import React from "react";
import Form from "react-bootstrap/Form";
import { Field } from "formik";
import {RatingType} from "models/ratingType";
import {Col, Row} from "react-bootstrap";

const QuestionMetaData = (props) =>{
  return (
    <fieldset className="border p-2">
      <legend className="w-auto">Question MetaData</legend>
      <Row>
        <Col sm={3}>
          <div className="form-group">
            <label>Rating Type: </label>
            <Field
              as="select"
              className="form-control"
              name="ratingType"
              value={props.ratingType}
            >
              <option value={RatingType.NonRating}>Non-Rating</option>
              <option value={RatingType.Likert}>Likert</option>
              <option value={RatingType.LikertNa}>Likert with N/A</option>
              <option value={RatingType.Nps}>NPS</option>
              <option value={RatingType.YesNo}>Yes / No</option>
            </Field>
          </div>
        </Col>
        <Col sm={3}>
          <Field as={Form.Check} inline label="Reverse Coded" type="switch" id="reverseCoded" name="reverseCoded" checked={props.reverseCoded} />
        </Col>
      </Row>
    </fieldset>
  );
};

export default QuestionMetaData;
