import React, {useState, useEffect} from "react";
import {Formik, Form, FieldArray, Field} from "formik";
import {Button, Col, Container, Row} from "react-bootstrap";
import DefinitionService from "services/survey_definition/definitions";
import QuestionInfo from "components/survey_definition/question/question_form/info";
import {ItemTypeForm} from "components/survey_definition/question/question_form/item_type/index";
import MatrixType from "./matrixType";
import QuestionNotes from "./notes";
import ResponseSet from "./responseSet";
import QuestionWordings from "./wording";
import QuestionMetaData from "./metaData";
import ResponseSetChoices from "./choices";
import {DisplayFormikState} from "components/helpers";
import QuestionManager from "managers/survey_definition/questionManager";
import { Question } from "managers/survey_definition/models";
import User from "models/user";
import * as Yup from 'yup';
import { Helmet } from 'react-helmet'
import SurveyItemDefinition from "models/surveyItemDefinition";

const QuestionSchema = Yup.object().shape({
  questionNumber: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),

  wordings: Yup.array()
    .min(1, 'At least 1 is required'),

  responseSet: Yup.number()
    .required('Required')
    .positive('Required'),

  choices: Yup.array()
    .when("responseSet",
      {
        is: 2,
        then: Yup.array().min(2, 'You need at least 2 options to have a choice')
      })
    .when("responseSet",
      {
        is: 3,
        then: Yup.array().min(2, 'You need at least 2 options to have a choice')
      }),

  matrixType: Yup.number()
    .required('Required')
    .positive('Required'),

  matrixItems: Yup.array()
    .when("matrixType",
      {
        is: 2,
        then: Yup.array().min(1, 'You need at least 1 matrix item')
      }),

  supportingDataId: Yup.number()
    .when("matrixType", {
      is: 3,
      then: Yup.number().required('Required')
    }),
});

const QuestionForm = (props: QuestionFormParameters) => {
  const token = props.currentuser.smToken;
  let questionManager = new QuestionManager(token);

  const [question, setQuestion] = useState<Question>();
  const [surveyItemDefinition, setSurveyItemDefinition] = useState<SurveyItemDefinition>();

  let definitionService = new DefinitionService(token);

  const formType = props.questionId === undefined ? 'new' : 'existing';
  const initialComponentLoad = () => {
    definitionService.retrieve().then(def => setSurveyItemDefinition(def.surveyItem));
  };

  const initAfterDefinitionLoads = () => {
    if(!surveyItemDefinition) return;

    if (formType === 'new') {
      let q = QuestionManager.blankModel();
      surveyItemDefinition.surveyTypes.forEach(d => {
        q.itemTypesBySurveyTypes.push({group: d.group, changedFlag: 1, surveyTypeId: d.id, isActive: true, name: d.name})
      });
      setQuestion(q);
    }
    else {
      questionManager.getById(props.questionId).then(setQuestion);
    }
  };

  useEffect(initAfterDefinitionLoads, [surveyItemDefinition]);
  useEffect(initialComponentLoad, [])

  const formSubmit = (values, {setSubmitting}) => {
    setSubmitting(true);

    if (formType === 'new') {
      questionManager.insert(values)
        .then(success => {
          if (success) {
            window.location.href = '/survey-definition/question';
          } else {
            alert("Saving Failed");
          }
        });
    } else {
      // Do update
      questionManager.update(props.questionId, values, question)
        .then(success => {
          if (success) {
            window.location.href = '/survey-definition/question';
          } else {
            alert("Saving Failed");
          }
        });
    }

    setSubmitting(false);
  };

  return ( (!surveyItemDefinition || !question) ? <></> :
    <Container fluid={true}>
      {!props.questionId
      ? <Helmet><title>StayMetrics Admin - Add Question</title></Helmet>
      : <Helmet><title>StayMetrics Admin - Edit Question</title></Helmet>}

      <Formik<Question> enableReinitialize={true}
              initialValues={question}
              onSubmit={formSubmit}
              validationSchema={QuestionSchema}>
        {
          props => {
            const {values, isSubmitting} = props;
            return (
              <Form>
                <Row>
                  <Col lg={4}>
                    <QuestionInfo
                      {...props}
                      questionNumber={values.questionNumber}
                      lastSmqUsed={surveyItemDefinition.lastSmqUsed}
                      formType={formType}
                    />

                    <FieldArray
                      name="wordings"
                      component={QuestionWordings}
                    />
                  </Col>
                  <Col lg={4}>
                    <fieldset className="border p-2">
                      <legend className="w-auto">Question Response Set</legend>

                      <Field
                        types={surveyItemDefinition.responseSetTypes}
                        responseSet={values.responseSet}
                        choices={values.choices}
                        component={ResponseSet}
                      />

                      <FieldArray
                        name="choices"
                        component={ResponseSetChoices}
                      />
                    </fieldset>

                  </Col>
                  <Col lg={4}>
                    <fieldset className="border p-2">
                      <Field
                        component={MatrixType}
                        matrixType={values.matrixType}
                        matrixItems={values.matrixItems}
                        supportingDataId={values.supportingDataId}
                      />
                    </fieldset>
                    <fieldset className="border p-2">
                      <Field component={ItemTypeForm} itemTypes={surveyItemDefinition.itemTypes}/>
                    </fieldset>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <QuestionMetaData ratingType={values.ratingType}
                                      reverseCoded={values.reverseCoded}/>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <QuestionNotes notes={values.notes}/>
                  </Col>
                </Row>
                <Button disabled={isSubmitting} variant="success" size="lg" type="submit">Submit</Button>
                <DisplayFormikState {...props}/>
              </Form>
            )
          }
        }

      </Formik>
    </Container>
  );
};
interface QuestionFormParameters{
  questionId: string;
  currentuser: User;
}
export default QuestionForm;
