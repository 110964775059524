import React from "react";
import {Field, FormikErrors, FormikTouched} from "formik";
import Form from "react-bootstrap/Form";

const QuestionInfo = (props: QuestionInfoParameters) =>{
  return (
    <fieldset className="border p-2">
      <legend className="w-auto">Question Info</legend>
      <Form.Group controlId="questionNumber">
        <Form.Row>
          <Form.Label className="inline-block mr-2">SMQ</Form.Label>
          {
            props.formType === 'new' && props.lastSmqUsed ?
              <Form.Text className="text-muted">Last SMQ used: {props.lastSmqUsed.join(', ')}</Form.Text>
              :
              <></>
          }
        </Form.Row>

        <Field as={Form.Control}
                      placeholder={"SMQ#"}
                      value={props.questionNumber}
                      name="questionNumber"
                      className={props.touched.questionNumber && props.errors.questionNumber ? "is-invalid" : ""}
                      />
        <Form.Control.Feedback type="invalid">
          {props.errors.questionNumber}
        </Form.Control.Feedback>
      </Form.Group>
    </fieldset>
  );
};

interface QuestionInfoParameters{
  questionNumber: string,
  lastSmqUsed: string[],
  touched: FormikTouched<QuestionInfoFormValues>,
  errors: FormikErrors<QuestionInfoFormValues>,
  formType: string,
}

interface QuestionInfoFormValues{
  itemTypeId:number,
  questionNumber:string
}

export default QuestionInfo;
