import React from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {FieldArray, Field} from "formik";

const StandardMatrixType = (props) => {
  let display = parseInt(props.form.values.matrixType) === 2;

  const style = {
    display: display ? 'block' : 'none'
  };

  return (
    <div style={style}>
      <FieldArray name="matrixItems">
        {(arrayHelpers) => {
          return <>
            {props.form.values.matrixItems && props.form.values.matrixItems.map((item, index) => {
              return <Row key={index}>
                <Col xs={2} lg={2}>
                  <Form.Group>
                    <Form.Label>Sort:</Form.Label>
                    <Field
                      name={`matrixItems.${index}.sort`}
                      value={item.sort}
                      placeholder="#"
                      type={"number"}
                      className={"form-control"}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col xs={8} lg={8}>
                  <Form.Group>
                    <Form.Label>Text:</Form.Label>
                    <Field
                      name={`matrixItems.${index}.text`}
                      placeholder="Enter Text"
                      value={item.text}
                      className={"form-control"}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col xs={2} lg={2}>
                  <Form.Group>
                    <Form.Label>&nbsp;</Form.Label>
                    <Button
                      className={"btn-block"}
                      variant={"outline-danger"}
                      onClick={() => {
                        arrayHelpers.remove(index);
                      }}
                    >Delete</Button>
                  </Form.Group>
                </Col>
              </Row>
            })}
            <Button
            variant="outline-success"
            onClick={()=> {
              arrayHelpers.push({
                id:0,
                text:"",
                sort: "",
              });
            }}>New</Button>
            </>
        }}
      </FieldArray>
    </div>
    )

};
export default StandardMatrixType;
