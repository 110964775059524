import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import {Nav, Navbar, NavDropdown} from "react-bootstrap";
import { Helmet } from 'react-helmet'
import Home from "./components/Home";
import Login from "./components/Login";
import Logout from "./components/Logout";
import AzureAdCallback from "components/AzureAd/AzureAdCallback";
import QuestionList from "./components/survey_definition/question/QuestionList";
import QuestionFormTabs from "./components/survey_definition/question/question_form_tabs";
import PrintView from "./components/survey_definition/survey/PrintView";
import SurveyBuilder2 from "./components/survey_definition/survey/SurveyBuilder2";
import SurveyForm from "./components/survey_definition/survey/SurveyForm";
import SurveyList from "./components/survey_definition/survey/SurveyList";
import NewClient from "./components/client_portal/NewClient";
import EditClient from "./components/client_portal/EditClient";
import ListClients from "./components/client_portal/ListClients";
import ClientUserForm from "./components/client_portal/client_users/ClientUserForm";
import ClientUserList from "./components/client_portal/client_users/ClientUserList";
import DownloadQualtricsResponses from "./components/survey_response/downloadQualtricsResponses";
import SurveysResponses from "./components/survey_response/surveysResponses";
import DataWarehouseUpdate from "components/survey_response/data_warehouse";
import User from "../src/models/user";
import '../src/App.css';
import Logo from "../src/images/new_logo.jpg"

export default function App() {
  return (
    <Router>
      <Helmet><title>StayMetrics Admin</title></Helmet>
      <Navbar expand="lg">
        <Navbar.Brand href="#home"><img className="logo" src={Logo} alt="logo"></img></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link href="/survey-definition/question">Questions</Nav.Link>
          <Nav.Link href="/survey-definition/survey">Survey Builder</Nav.Link>
          <NavDropdown title="Survey Responses" id="basic-nav-dropdown">
              <NavDropdown.Item href="/survey-responses/qualtrics/surveys">Surveys</NavDropdown.Item>
              <NavDropdown.Item href="/survey-responses/qualtrics/download">Manually Download Responses</NavDropdown.Item>
              <NavDropdown.Item href="/survey-responses/datawarehouse">Data Warehouse Update</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Clients" id="basic-nav-dropdown">
              <NavDropdown.Item href="/client-portal/clients/list">Client List</NavDropdown.Item>
              <NavDropdown.Item href="/client-portal/client-users">Client Users</NavDropdown.Item>
            </NavDropdown>

            <Nav.Link href="/logout">Logout</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <div>
        <Switch>
          <PrivateRoute exact path="/" component={Home} />
          <Route exact path={'/azuread/callback'} component={AzureAdCallback} />
          <Route exact path={"/login"} component={Login} />
          <Route exact path={"/logout"} component={Logout} />
          <PrivateRoute exact path="/survey-definition/question" component={QuestionList} />
          <PrivateRoute exact path="/survey-definition/survey" component={SurveyList} />
          <PrivateRoute exact path="/survey-definition/survey/new" component={SurveyForm} />
          <PrivateRoute exact path="/survey-definition/question/new" component={QuestionFormTabs} />
          <PrivateRoute path="/survey-definition/survey/:surveyId/build" component={SurveyBuilder2} />
          <PrivateRoute path="/survey-definition/survey/:surveyId/print" component={PrintView} />
          <PrivateRoute path="/survey-definition/survey/:surveyId" component={SurveyForm} />
          <PrivateRoute path="/survey-definition/question/:questionId" component={QuestionFormTabs} />
          <PrivateRoute exact path="/survey-responses/qualtrics/surveys" component={SurveysResponses} />
          <PrivateRoute exact path="/survey-responses/qualtrics/download" component={DownloadQualtricsResponses} />
          <PrivateRoute exact path="/client-portal/clients/new" component={NewClient} />
          <PrivateRoute exact path="/client-portal/clients/edit/:clientId" component={EditClient} />
          <PrivateRoute exact path="/client-portal/clients/list" component={ListClients} />
          <PrivateRoute exact path="/survey-responses/datawarehouse" component={DataWarehouseUpdate} />

          <Route exact path="/client-portal/client-users" component={ClientUserList} />
          <Route path="/client-portal/client-users/:clientUserId" component={ClientUserForm} />

        </Switch>
      </div>
    </Router>
  );
}

const PrivateRoute = ({ component: Component, ...rest }) => {

  if(!User.isAuthorized() ) {
    User.removeStorageUser();
    window.location.href = '/login';
  }

  return (
    User.isAuthorized()
    ? <Route {...rest} render={(props) => (
      <Component {...props} currentuser={new User()} />
    )} />
    : <Redirect to='/login' />
  );
};
