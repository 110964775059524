import React, {useEffect, useState} from "react";
import SupportingDataService from "services/survey_definition/supportingData";
import {retrieveToken} from "utils";
import {Field} from "formik";
import {Form} from "react-bootstrap";

const SupportingDataMatrixType = (props) => {

  const style = {display: props.display ? 'block' : 'none'};
  let token = retrieveToken();
  let supportingDataService = new SupportingDataService(token);
  let [supportingDatums, setSupportingDatums] = useState([]);

  useEffect(()=>{
    supportingDataService.retrieve()
      .then(datums => {
        setSupportingDatums(datums);
      });
  }, []);
  return (
    <div style={style}>
      <Form.Group>
        <label>Select Supporting Data Type:</label>
      <Field
        as="select"
        value={props.form.values.supportingDataId}
        name="supportingDataId"
        className={`form-control ${props.form.touched.supportingDataId && props.form.errors.supportingDataId ? "is-invalid" : ""}`}
      >
        <option value={""}>Select Supporting Data</option>
        {
          supportingDatums && supportingDatums.map((option, index) => {
            return <option key={index} value={option.supportingDataId}>{option.description}</option>
          })
        }
      </Field>
        <Form.Control.Feedback type="invalid">
          {props.form.errors.supportingDataId}
        </Form.Control.Feedback>
      </Form.Group>
    </div>
  );
};

export default SupportingDataMatrixType;
